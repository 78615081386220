import React from "react";
import * as style from "@/styles/about-us/irishlife.module.css";
import irishLifeImage from "@/images/about-us-irish-life.png";

const IrishLife = () => {
  return (
    <section aria-label="irish-life" className={style.section}>
      <div className={style.wrapper}>
        <div className={style.content}>
          <img
            src={irishLifeImage}
            alt="irish-life logo"
            className={style.image}
            width={231}
            height={75}
            loading="lazy"
          />
          <div className={style.textWrapper}>
            <p className={style.title}>
              With the Multiply-powered holistic advice solution, Irish Life is
              helping their customers plan for a better financial future.
            </p>
            <p className={style.desc}>
              We started working with our first B2B customer, Irish Life, in
              2022. Irish Life is a market leader in assurance and pensions in
              Ireland, with more than 1.6 million customers.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IrishLife;
