import React from "react";
import * as style from "@/styles/about-us/service.module.css";
import { motion } from "framer-motion";
import doImage from "@/images/about-us-do.jpg";
import workImage from "@/images/about-us-work.jpg";

const banners = [
  {
    title: "What we do",
    description:
      "At Multiply, we give financial institutions a platform that lets them automate their advice lifecycle. We have modules that provide automated advice, customer insights that help you make the right decisions at the right time, and dashboards that let you keep track of your process. All of this can be customised to meet the needs of your company. We believe that when the right partners collaborate, they create something greater than they could alone.",
    image: doImage,
  },
  {
    title: "How we work",
    description:
      "To build a long-lasting, sustainable, and purposeful business, we focus on creating engaged team members and delighted customers. Our flexible approach creates an environment where our team members feel valued and appreciated, allowing them to do their best work. We give our customers a positive and memorable experience so that they will want to keep doing business with us for a long time.",
    image: workImage,
  },
];

const Service = (props: {
  banner: { title: string; description: string; image: any };
}) => {
  const { banner } = props;
  const variants = {
    open: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "50%" },
  };
  return (
    <div className={style.banner}>
      <div className={style.bannerImageWrapper}>
        <motion.img
          variants={variants}
          transition={{ duration: 0.5 }}
          initial={"hidden"}
          whileInView={"open"}
          src={banner.image}
          alt={banner.title}
          viewport={{ once: true }}
          loading="lazy"
        />
      </div>
      <div className={style.bannerContent}>
        <span>
          <h3>{banner.title}</h3>
        </span>
        <p>{banner.description}</p>
      </div>
    </div>
  );
};

const ServicePanel = () => {
  return (
    <section aria-label="our service" className={style.section}>
      <div className={style.wrapper}>
        <div className={style.header}>
          <h4 className={style.headerTitle}>
            We exist to help people achieve their goals and improve their
            financial wellbeing.
          </h4>
          <p className={style.headerDesc}>
            Our holistic, personalised advice shows them
            how, and we make it happen.
          </p>
        </div>
        <div className={style.bannerWrapper}>
          {banners.map((banner, i) => (
            <Service banner={banner} key={i} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicePanel;
