import React from "react";
import * as style from "@/styles/about-us/corevalues.module.css";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import teamImage from "@/images/about-us-team.jpg";
import customerFirstImage from "@/images/about-us-customer-first.svg";
import teamWorkImage from "@/images/about-us-team-work.svg";
import constantlyEvolvingImage from "@/images/about-us-constantly-evolving.svg";
import moveQuicklyImage from "@/images/about-us-move-quickly.svg";

const philosophies = [
  {
    icon: customerFirstImage,
    label: "We always put the customer\u00A0first.",
    desc: "Our number one goal is to give businesses the tools they need to help people improve their financial wellbeing.",
  },
  {
    icon: teamWorkImage,
    label: "We work as a team.",
    desc: "We are all working toward the same objective, communicating with openness and respect.",
  },
  {
    icon: constantlyEvolvingImage,
    label: "We are constantly evolving.",
    desc: " We celebrate success and are always looking for ways to get better.",
  },
  {
    icon: moveQuicklyImage,
    label: "We move quickly.",
    desc: " We pursue ambitious goals through swift decision-making, brilliant prioritisation and incremental change.",
  },
];

const CoreValues = () => {
  return (
    <section aria-label="core-values section" className={style.section}>
      <div className={style.wrapper}>
        <div className={style.bannerWrapper}>
          <Team />
        </div>
        <div className={style.header}>
          <h4 className={style.headerTitle}>Core Values</h4>
          <p className={style.headerDesc}>
            These are the rules that we live our lives by every day.
          </p>
        </div>
        <div className={style.cards}>
          {philosophies.map((philosophy) => (
            <div key={philosophy.label} className={style.card}>
              <img
                src={philosophy.icon}
                alt={philosophy.label}
                className={style.cardImage}
                loading="lazy"
              />
              <p className={style.cardLabel}>{philosophy.label}</p>
              <p className={style.cardDesc}>{philosophy.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CoreValues;

const Team = () => {
  const variants = {
    open: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "50%" },
  };

  return (
    <div className={style.banner}>
      <div className={style.bannerImageWrapper}>
        <motion.img
          variants={variants}
          transition={{ duration: 0.5 }}
          initial={"hidden"}
          whileInView={"open"}
          src={teamImage}
          alt="team"
          viewport={{ once: true }}
          loading="lazy"
        />
      </div>
      <div className={style.bannerContent}>
        <span>
          <h3>Meet our team</h3>
        </span>
        <p>
          We are a team of ex-Googlers, Lehman Brothers employees and Cambridge
          PhDs, who decided to take our knowledge and put it towards helping
          everyday people with their money.
        </p>
        <Link
          to="https://www.linkedin.com/company/multiply.ai/people/"
          target="_blank"
        >
          <button className={style.meetButton}>Meet the team</button>
        </Link>
      </div>
    </div>
  );
};
