// extracted by mini-css-extract-plugin
export var banner = "corevalues-module--banner--tTSlw";
export var bannerContent = "corevalues-module--bannerContent--2iVfG";
export var bannerImageWrapper = "corevalues-module--bannerImageWrapper--eLJii";
export var bannerWrapper = "corevalues-module--bannerWrapper--IgLBp";
export var card = "corevalues-module--card--TFIo1";
export var cardDesc = "corevalues-module--cardDesc--YskJT";
export var cardImage = "corevalues-module--card-image--dxsTk";
export var cardLabel = "corevalues-module--cardLabel--qZUiW";
export var cards = "corevalues-module--cards--ci0rI";
export var header = "corevalues-module--header--g0f7r";
export var headerDesc = "corevalues-module--headerDesc--Hv89a";
export var headerTitle = "corevalues-module--headerTitle--YeQRX";
export var meetButton = "corevalues-module--meetButton--cclh2";
export var section = "corevalues-module--section--6g3Lj";
export var wrapper = "corevalues-module--wrapper--fishL";