// extracted by mini-css-extract-plugin
export var achievement = "journey-module--achievement--+I9Ih";
export var achievementsTitle = "journey-module--achievementsTitle--x5Q9i";
export var achievemetsWrapper = "journey-module--achievemetsWrapper--RH3k5";
export var banner = "journey-module--banner--auAiM";
export var bannerContent = "journey-module--bannerContent--4Y4w5";
export var bannerImageWrapper = "journey-module--bannerImageWrapper--haKQ+";
export var bannerImageWrapperSm = "journey-module--bannerImageWrapperSm--AtMDg";
export var bannerWrapper = "journey-module--bannerWrapper--4P7S8";
export var imgStarted = "journey-module--img-started--o9OLx";
export var section = "journey-module--section--6Wht+";
export var wrapper = "journey-module--wrapper--iqmTA";