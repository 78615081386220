import React from "react";
import Layout from "@/components/Layout";
import HeroSection from "@/components/about-us/HeroSection";
import JourneyPanel from "@/components/about-us/JourneyPanel";
import IrishLife from "@/components/about-us/IrishLife";
import Technology from "@/components/about-us/Technology";
import Achievements from "@/components/Achievements";
import ContactForm from "@/components/ContactForm";
import ServicePanel from "@/components/about-us/ServicePanel";
import CoreValues from "@/components/about-us/CoreValues";
import { GTM } from "@/components/GTM";

const AboutUs = () => {
  return (
    <Layout>
      <main>
        <HeroSection />
        <JourneyPanel />
        <IrishLife />
        <Technology />
        <ServicePanel />
        <CoreValues />
        <Achievements />
        <ContactForm />
      </main>
    </Layout>
  );
};

export default AboutUs;

export const Head = () => {
  return (
    <>
      <GTM />
      <title>About Us | Multiply</title>;
      <meta
        name="description"
        content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
      ></meta>
      <meta
        name="og:description"
        content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
      ></meta>
      <link rel="canonical" href="https://www.multiply.ai/" />
      <link rel="canonical" href="https://blog.multiply.ai/" />
    </>
  );
};
