// extracted by mini-css-extract-plugin
export var achievement = "service-module--achievement--FUMrk";
export var achievementsTitle = "service-module--achievementsTitle--gnegg";
export var achievemetsWrapper = "service-module--achievemetsWrapper--QdALe";
export var banner = "service-module--banner--5n466";
export var bannerContent = "service-module--bannerContent--QzR7Q";
export var bannerImageWrapper = "service-module--bannerImageWrapper--55Zk5";
export var bannerWrapper = "service-module--bannerWrapper--odk-X";
export var header = "service-module--header---kCXE";
export var headerDesc = "service-module--headerDesc--YLXAi";
export var headerTitle = "service-module--headerTitle--AEWf3";
export var section = "service-module--section--ouirV";
export var wrapper = "service-module--wrapper--ANC9R";