import * as style from "@/styles/about-us/herosection.module.css";
import React from "react";

const HeroSection = () => {
  return (
    <section aria-label="hero section" className={style.wrapper}>
      <div className={style.contentWrapper}>
        <div className={style.content}>
          <h1 className={style.title}>
            We're making financial planning
            <br className="hidden md:inline" />
            &nbsp;
            <div className="inline-grid">
              <span className={style.mark}>accessible to&nbsp;</span>
            </div>
            <div className="inline-grid">
              <span className={style.mark}>everyone </span>
            </div>
          </h1>
          <p className={style.subtitle}>
            With the next generation of financial advice.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
