import React from "react";
import * as style from "@/styles/about-us/journey.module.css";
import { motion } from "framer-motion";
import startImage from "@/images/about-us-start.jpg";
import missionImage from "@/images/about-us-mission.png";
import notEnoughImage from "@/images/about-us-not-enough.jpg";

const banners = [
  {
    id: "img-started",
    classText: "bannerImageWrapperSm",
    title: "It all started with two people and a few questions…",
    description:
      "How can we put the power of a personal financial adviser in the hands of everyone? What can we do to help financial advisers? How can we improve financial wellbeing in the UK and beyond?",
    image: startImage,
  },
  {
    id: "img-mission",
    classText: "bannerImageWrapper",
    title: "…And a mission to help bridge the advice gap was born.",
    description:
      "Our drive to answer these questions led us to create something new - a fully automated, FCA authorised, financial advice app designed to bring financial advice to the masses. Since 2019, we've helped over 10,000 families with plans and have generated more than 250k advice recommendations for them.",
    image: missionImage,
  },
  {
    id: "img-enough",
    classText: "bannerImageWrapper",
    title: "But that wasn't enough.",
    description:
      "We're so proud of our technology and its ability to improve people's financial lives that we want to get it into the hands of as many people as possible. We now license our technology out to financial institutions, partnering closely with them to understand their needs. Then we innovate like crazy to give them the technology to get there. Together, we're shaping the future of financial advice.",
    image: notEnoughImage,
  },
];

const Journey = (props: {
  banner: {
    id: string;
    classText: string;
    title: string;
    description: string;
    image: any;
  };
}) => {
  const { banner } = props;
  const variants = {
    open: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "50%" },
  };
  const styleName =
    banner.id === "img-started"
      ? style.bannerImageWrapperSm
      : style.bannerImageWrapper;
  return (
    <div className={style.banner}>
      <div className={styleName}>
        <motion.img
          id={banner.id}
          variants={variants}
          transition={{ duration: 0.5 }}
          initial={"hidden"}
          whileInView={"open"}
          src={banner.image}
          alt={banner.title}
          viewport={{ once: true }}
          loading="lazy"
        />
      </div>
      <div className={style.bannerContent}>
        <span>
          <h3>{banner.title}</h3>
        </span>
        <p>{banner.description}</p>
      </div>
    </div>
  );
};

const JourneyPanel = () => {
  return (
    <section aria-label="our journey" className={style.section}>
      <div className={style.wrapper}>
        <div className={style.bannerWrapper}>
          {banners.map((banner, i) => (
            <Journey banner={banner} key={i} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default JourneyPanel;
