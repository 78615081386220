import React from "react";
import { Link } from "gatsby";
import * as style from "@/styles/about-us/technology.module.css";

const technologies = [
  {
    year: "2016",
    baseTexts: {
      baseOne: "Vivek and Mike meet via",
      baseTwo: "",
      baseThree: "",
    },
    richTexts: {
      richOne: "Entrepreneur First",
      richTwo: "",
    },
    richLinks: {
      linkOne: "https://www.joinef.com/",
      linkTwo: "",
    },
  },
  {
    year: "2017",
    baseTexts: {
      baseOne: "Multiply joins the",
      baseTwo: "the FCA publishes",
      baseThree: "and supports the development of automated advice",
    },
    richTexts: {
      richOne: "Advice Unit,",
      richTwo: "FAMR",
    },
    richLinks: {
      linkOne:
        "https://www.fca.org.uk/news/press-releases/announces-firms-participated-advice-unit-publishes-famr-baseline-report",
      linkTwo:
        "https://www.fca.org.uk/firms/financial-advice-market-review-famr",
    },
  },
  {
    year: "2018",
    baseTexts: {
      baseOne: "",
      baseTwo: "to support Multiply's launch",
      baseThree: "",
    },
    richTexts: {
      richOne: "Octopus Ventures leads seed round",
      richTwo: "",
    },
    richLinks: {
      linkOne:
        "https://www.uktech.news/news/octopus-ventures-invests-1m-to-support-launch-of-automation-fintech-firm-multiply-20180605",
      linkTwo: "",
    },
  },
  {
    year: "2019",
    baseTexts: {
      baseOne: "Multiply becomes the",
      baseTwo: "",
      baseThree: "",
    },
    richTexts: {
      richOne: "First FCA authorised finance advice app",
      richTwo: "",
    },
    richLinks: {
      linkOne:
        "https://www.fintechfutures.com/2019/07/multiplys-financial-advice-app-wins-fca-approval/",
      linkTwo: "",
    },
  },
  {
    year: "2020",
    baseTexts: {
      baseOne: "Customers are served",
      baseTwo: "",
      baseThree: "",
    },
    richTexts: {
      richOne: "over 250,000 advice recommendations",
      richTwo: "",
    },
    richLinks: {
      linkOne:
        "https://multiply-blog.appspot.com/how-we-generated-over-250-000-digital-advice-recommendations-and-you-can-too/",
      linkTwo: "",
    },
  },
  {
    year: "2021",
    baseTexts: {
      baseOne: "Multiply becomes an",
      baseTwo: "",
      baseThree: "",
    },
    richTexts: {
      richOne: "end-to-end AI financial adviser",
      richTwo: "",
    },
    richLinks: {
      linkOne:
        "https://www.altfi.com/article/7461_with-investments-multiply-is-now-an-end-to-end-ai-financial-adviser",
      linkTwo: "",
    },
  },
  {
    year: "2022",
    baseTexts: {
      baseOne: "Focus switches to the B2B market, launching to",
      baseTwo: "1.6m+ customers",
      baseThree: "",
    },
    richTexts: {
      richOne: "Irish Life's",
      richTwo: "",
    },
    richLinks: {
      linkOne: "https://www.irishlife.ie/get-advice/",
      linkTwo: "",
    },
  },
  {
    year: "2023 >",
    baseTexts: {
      baseOne:
        "Multiply gets ever closer to putting a financial plan in everyone's hands",
      baseTwo: "",
      baseThree: "",
    },
    richTexts: {
      richOne: "",
      richTwo: "",
    },
    richLinks: {
      linkOne: "",
      linkTwo: "",
    },
  },
];

const Technology = () => {
  return (
    <section
      aria-label="next-generation-technology section"
      className={style.section}
    >
      <div className={style.wrapper}>
        <h4 className={style.sectionTitle}>
          The <span className={style.underline}>next generation</span> of
          financial technology.
        </h4>
        <div className={style.cards}>
          {technologies.map((technology, i) => {
            const { year, baseTexts, richTexts, richLinks } = technology;
            const { baseOne, baseTwo, baseThree } = baseTexts;
            const { richOne, richTwo } = richTexts;
            const { linkOne, linkTwo } = richLinks;
            return (
              <div className={style.card} key={i}>
                <h4 className={style.cardLabel}>{year}</h4>
                <p className={style.cardDesc}>
                  {baseOne}{" "}
                  <Link to={linkOne} target="_blank">
                    <span className={style.richDesc}>{richOne}</span>
                  </Link>{" "}
                  {baseTwo}{" "}
                  <Link to={linkTwo} target="_blank">
                    <span className={style.richDesc}>{richTwo}</span>
                  </Link>{" "}
                  {baseThree}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Technology;
